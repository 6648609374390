import TinderCard from "react-tinder-card";
import data from "../animals.json"
import "./cardStyles.scss"
import redo from "../assets/redo.jpeg";
import heart from "../assets/heart.jpeg";
import x from "../assets/x.jpeg";
import React, {useMemo, useRef, useState} from "react";
import inbox from "../assets/inbox.png";
import paws from "../assets/paws.png";
import arrow from "../assets/arrow.png"
function Cards(){

    //https://codesandbox.io/s/8tzm6?file=/src/examples/Advanced.js
    const[currentIndex, setCurrentIndex] = useState(Object.keys(data.items).length - 1)

    // const [lastDirection, setLastDirection] = useState()

    const currentIndexRef = useRef(currentIndex)

    const childRefs = useMemo(
        () =>
            Array(Object.keys(data.items).length)
                .fill(0)
                .map((i) => React.createRef()),
        []
    )

    const updateCurrentIndex = (val) => {
        setCurrentIndex(val)
        currentIndexRef.current = val
    }

    const canGoBack = currentIndex < Object.keys(data.items).length

    const canSwipe = currentIndex >= 0

    const swiped = async(direction, index) => {
        if(direction === "right") {
            await togglerMSG(index)
        }
        updateCurrentIndex(index - 1)
    }

    const outOfFrame = (name, idx) => {
        currentIndexRef.current >= idx && childRefs[idx].current.restoreCard()
    }

    const swipe = async (dir) => {
        if(canSwipe && currentIndex < Object.keys(data.items).length) {
            await childRefs[currentIndex].current.swipe(dir)
            if(dir === "right") {
                await togglerMSG(currentIndex)
            }
        }
    }

    const goBack = async () => {
        if (!canGoBack) return
        const newIndex = currentIndex + 1
        updateCurrentIndex(newIndex)
        await childRefs[newIndex].current.restoreCard()
    }

    const [inboxToggle, setInboxToggle] = useState(false)
    const idRightList = useRef([])

    const togglerMSG = async(id) => {
        idRightList.current.push(id)
    }

    const toggleInbox = () => {
        setInboxToggle(!inboxToggle);
    };

    const[clickedIndex, setClickedIndex] = useState(null)

    const setClickedDM = (index) => {
        setClickedIndex(index)
    }

    return (
        <div className={"appWrapper"}>
            <div className={"headerWrapper"}>
                <img src ={inbox} onClick={toggleInbox} alt={"img"}/>
                <img src={paws} alt={"<a href=\"https://www.flaticon.com/free-icons/dog\" title=\"dog icons\">Dog icons created by Freepik - Flaticon</a>"}/>
            </div>
            <div className={"card"}>

                <div className={inboxToggle ? "inbox" : "hidden"}>
                    <h1>Inbox</h1>
                    {data.items.map((item, index) => (
                        <div className={idRightList.current.includes(index) ? "visibleMSG" : "hidden"}>
                            <img className={"profileImg"} src={item.image} onClick={()=> setClickedDM(index)}/>
                            <p key={index}>{item.dm}</p>
                            <hr/>

                            <div className={index === clickedIndex? "DMS" : "hidden"}>
                                <img className={"backBtn"} src={arrow} onClick={()=>setClickedDM(-1)}/>
                                <p className={"nameTag"}>{item.name}</p>
                                <div className={"name-msg-line"}/>
                                <p key={index} className={"message"}>{item.dm}</p>
                                <div className={"sendMsg"}>
                                    <div className={"inputField"}>
                                        <input/>
                                        <div className={"placeholder"}>Type a message <span>Send</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    )}
                </div>
                {data.items.map((item, index) => (
                        <TinderCard
                            key={item.name}
                            className={"swipe"}
                            preventSwipe={["up", "down"]}
                            ref={childRefs[index]}
                            onSwipe={(dir) => swiped(dir, index)}
                            onCardLeftScreen={() => outOfFrame(item.name, index)}
                        >
                            <img className={"cardImg"} src={item.image} alt={"img"}/>
                            <div className={"bio"}>
                                <div className={"shadow"}/>
                                <p className={"name"}>{item.name}
                                    <span> {item.age}</span>
                                    <br/>
                                    <span className={"subBio"}>
                                        {item.bio}
                                    </span>
                                </p>
                            </div>
                        </TinderCard>
                    )
                )}
            </div>
            <div className={inboxToggle ? "hidden" : "footerWrapper"}>
                <img src={x} onClick={() => swipe("left")} alt={"alt"}/>
                <img src={redo} onClick={() => goBack()} alt={"alt"}/>
                <img src={heart} onClick={() => swipe("right")} alt={"alt"}/>
            </div>
        </div>


    )

}

export default Cards;