import './App.css';
import Cards from "./components/cards"
// import Footer from "./components/Footer"
// import Header from "./components/Header"
import "./App.css"

function App() {
  return (
      <div className={"divWrapper"}>
          {/*<Header/>*/}
          <Cards/>
          {/*<Footer/>*/}
      </div>

  );
}

export default App;
